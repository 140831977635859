<ng-http-loader spinner="sk-wave" [debounceDelay]="300" [minDuration]="500" [backdropBackgroundColor]="'#000000'"
  [backgroundColor]="'#b8b9ba'" [filteredHeaders]="['X-WX-No-Spinner']">
</ng-http-loader>

<!-- Information & Alert System - It is a mess but oh well -->
<div [style.background-color]="alert.type === 'alert' ? '#ffd000' : '#3975e4'" class="p-1" *ngIf="alert">
  <div class="container pb-1" style="margin-top: 10px;">
    <!--Banner-->
    <div class="d-flex mousePointer" (click)="toggleData()">
      <mat-icon class="me-4" style="margin-top: 3px;" [style.color]="alert.type === 'alert' ? 'black' : 'white'">{{alert.type == "alert" ? 'warning' : 'info'}}</mat-icon>
      <h5 style="font-weight: 500; font-size: 17px; margin-top: 3px;" [style.color]="alert.type === 'alert' ? 'black' : 'white'">{{alert.title}}</h5>
      <mat-icon class="ms-3 arrow-icon" [class.rotate]="!alertHide" [style.color]="alert.type === 'alert' ? 'black' : 'white'">keyboard_arrow_down</mat-icon>
    </div>
    <!-- Information -->
    <div [ngClass]="alertHide ? 'alert-hide' : 'alert-show'">
      <p class='mt-2' style='font-size: 15px; font-weight: 400;' [style.color]="alert.type === 'alert' ? 'black' : 'white'">{{alert.message}}</p>
      <p style='font-size: 15px; font-weight: 400; margin-top: -10px;' *ngIf="alert.message2" [style.color]="alert.type === 'alert' ? 'black' : 'white'">{{alert?.message2}}</p>
      <div class="d-flex" *ngIf="alert.link">
        <a class="nav-link" style="font-weight: 500;" href="{{alert.link}}" [style.color]="alert.type === 'alert' ? 'black' : 'white'">Find out more</a>
        <mat-icon class="ms-2" [style.color]="alert.type === 'alert' ? 'black' : 'white'">arrow_forward</mat-icon>
      </div>
    </div>
  </div>
</div>

<div class="top-gradient"></div>

<div class="wxCardPadding">
  <div class="container wxCard shadow-lg">
    <div class="black-wx-logo">
      <img src="/assets/imgs/wx-logo-black.png" style="width: 150px;" alt="Logo">
    </div>

    <div class="white-wx-logo">
      <img src="/assets/imgs/wx-logo-white.png" style="width: 150px;" alt="Logo">
    </div>
    <router-outlet></router-outlet>
  </div>
</div>