import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAlert } from './models/interface/IAlert';
import { Subject, takeUntil } from 'rxjs';
import { SiteService } from './services/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject();

  alert: IAlert = null
  alertHide: boolean = true

  constructor(private _siteService: SiteService) { }

  ngOnInit() {
    this._siteService.getActiveAlert().pipe(takeUntil(this._destroyed$)).subscribe((alert) => {
      if (alert) {
        this.alert = alert
      }
    })
  }

  ngOnDestroy() {
    this._destroyed$.next("")
  }

  toggleData() {
    this.alertHide = !this.alertHide;
  }
}
